import React from "react"

class NotFoundPage extends React.Component {
    render() {
        return (
            <div>
                <h1>Not Found</h1>
                <p>You just hit a page that doesn&#39;t exist... the sadness.</p>
            </div>
        )
    }
}

export default NotFoundPage
